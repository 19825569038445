<template>
  <solution-list-v2 :list-data="listData"></solution-list-v2>
</template>

<script>
import SolutionListV2 from '../mainlistv2/Solution'

export default {
  name: 'SolutionChosenlist',
  props: {
    listData: {
      type: Object,
      default: {
        total: 0,
        list: []
      }
    }
  },
  components: {
    SolutionListV2
  }
}
</script>
